import axios from 'axios'

// 环境的切换
let apiBaseURL = ''
let siteBaseURL = ''
if (process.env.NODE_ENV == 'development') {
  apiBaseURL = 'https://navigateapitest.biswitnc.com';
  siteBaseURL = 'http://192.168.0.125:44444';
} else if (process.env.NODE_ENV == 'debug') {
  apiBaseURL = 'https://navigateapitest.biswitnc.com';
  siteBaseURL = 'http://192.168.0.125:44444';
} else if (process.env.NODE_ENV == 'production') {
  apiBaseURL = 'https://navigateapitest.biswitnc.com';
  siteBaseURL = 'http://192.168.0.125:44444';
}

const service = axios.create({
  // baseURL,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  }
})

// 请求拦截器
service.interceptors.request.use(
 
  config => {
    // if (sessionStorage.getItem('Authorization')) {
    //   config.headers.token = sessionStorage.getItem('Authorization');
    // }
    return config;
  },
  error => {
    return Promise.reject(error);
});

// 响应拦截器
service.interceptors.response.use(
  res => {
    const status = res.data.code;
    // if (status !== 1000) {
    //   Message({
    //     message: '请求失败',
    //     type: "error"
    //   });
    // }
    return res;
  }, error => {
    return Promise.reject(error);
});

export default {
  ...service,
  //改写get传参方式,去掉params前缀
  get(url, data) {
    return service.get(siteBaseURL+url, {
      params: data
    })
  },
  post (url, data) {
    return service({
      url: apiBaseURL + url,
      data,
      method:'post'
    })
  }
}