import axios from '@/utils/axios'
const api = {
  map: {
    getSpace: (params) => axios.post(`/navigate/api/space/select/by/appKey`, params),
    searchPlace: (params) => axios.post(`navigate/api/place/select/by/name`, params),
  },
  site: {
    // beacons: (params) => axios.get(`/locators/${params.macs}` ),
    beacons: (params) => axios.post(`/navigate/api/AOALocation`, params),
  }
}

export default (Vue) => {
    Vue.prototype.$api = api
}
