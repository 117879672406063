<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import VConsole from 'vconsole';
export default {
  name: '',
  data() {
    return {
    }
  },
  created () {
    if (process.env.NODE_ENV == 'development' || process.env.NODE_ENV == 'test') {
      let vConsole = new VConsole();
    }
  },
  mounted() {
    this.safariHacks()
  },
  methods: {
    safariHacks() {
      let windowsVH = window.innerHeight / 100;
      document.querySelector('#app').style.setProperty('--vh', windowsVH + 'px');
      window.addEventListener('resize', function () {
        document.querySelector('#app').style.setProperty('--vh', windowsVH + 'px');
      });
      console.log(document.querySelector('#app').offsetHeight);
    }
  }
}
</script>
<style lang="scss">
@import '@/utils/lt.css';
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
</style>
